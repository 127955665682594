/**
 * This is a temporary module that acts as a midpoint between deprecating
 * the use of sprites, and using compressed SVGs.
 *
 * This file is a purged version from the generated sprites.scss file.
 *
 * The way sprites.scss was generated was like this:
 *   - A webpack plugin would look up for all the *.png files inside
 *     /images, at root level.
 *   - It'd generate a sprite containing all those images, and a SCSS file
 *     with classes that were taken from the image names, all using the same
 *     background image, with custom position and size.
 *
 *     For example:
 *        Having the file '/images/arrow-sube.png', it'd generate:
 *
 *        .icon-arrow-sube {
 *          display: block;
 *          background-image: url(/images/iconSprites.png);
 *          background-size: 641px 592.5px;
 *          background-position: -420px -289px;
 *          width: 11px;
 *          height: 8px;
 *          content: "";
 *        }
 *
 * This way, you could add the class 'icon-arrow-sube', and it'd display
 * the right section of the sprite that corresponded to that icon.
 *
 * However, we needed to get rid of the SpritePlugin to ease a future migration
 * to angular-cli. Plus, we have already switched to HTTP/2, which greatly
 * reduces the trade-off of having to download multiple images by allowing
 * a single connection for multiple requests.
 * Check https://http2.golang.org/gophertiles
 *
 * Also, many images were not being used, or being displayed both from
 * the sprite, and imported as image with <img src="">, removing the benefit
 * from having them as sprite.
 *
 * ----
 *
 * To depracate the sprites, we took the original 'sprites.scss', and started
 * to search for all the file names (the class name without the .icon- prefix)
 * in the .html files.
 *
 * They either:
 *   - didn't appear, so we deleted the classes, and the files,
 *   - appeared, but as <img src="">, so we deleted the classes and kept the image, or
 *   - appeared as classes, so we kept the classes and the image, but updated the url.
 *
 * The ones that are kept should eventually be replaced with .svg files, which
 * allow gzip and br compression.
 */

/**
 * Common class to prevent duplicating this code.
 * We serve the images at twice their displayed size, so we need to set
 * the background -size, -position, and -repeat attributes; and the width
 * and height for the images.
 */

.css-background-icon {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
}

.icon-arrow-baja {
  @extend .css-background-icon;

  background-image: url(/images/icons/arrow-down.svg);
  width: 16px;
  height: 16px;
}

.icon-arrow-cero {
  @extend .css-background-icon;

  background-image: url(/images/arrow-cero.png);
  width: 24px;
  height: 24px;
}

.icon-arrow-sube {
  @extend .css-background-icon;

  background-image: url(/images/icons/arrow-up.svg);
  width: 16px;
  height: 16px;
}

.icon-atm-graphic-down1-down2 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-down1-down2.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-down1 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-down1.png);
  width: 22.5px;
  height: 14px;
}

.icon-atm-graphic-down2-up1 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-down2-up1.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-down2 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-down2.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-up1-up2 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-up1-up2.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-up1 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-up1.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-up2-down1 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-up2-down1.png);
  width: 25px;
  height: 14.5px;
}

.icon-atm-graphic-up2 {
  @extend .css-background-icon;

  background-image: url(/images/atm-graphic-up2.png);
  width: 22.5px;
  height: 14px;
}

.icon-atm-icn-cancel-replace-disable {
  @extend .css-background-icon;

  background-image: url(/images/atm-icn-cancel-replace-disable.png);
  width: 17px;
  height: 18px;
}

.icon-atm-icn-cancel-replace {
  @extend .css-background-icon;

  background-image: url(/images/atm-icn-cancel-replace.png);
  width: 17px;
  height: 19px;
}

.icon-atm-icn-ordenes {
  @extend .css-background-icon;

  background-image: url(/images/icons/atm-icn-orders.svg);
  width: 22px;
  height: 22px;
}

.icon-atm-icn-x-disable {
  @extend .css-background-icon;

  background-image: url(/images/atm-icn-x-disable.png);
  width: 14px;
  height: 14px;
}

.icon-cancel {
  @extend .css-background-icon;

  background-image: url(/images/cancel.png);
  width: 14px;
  height: 14px;
}

.icon-drag {
  @extend .css-background-icon;

  background-image: url(/images/drag.png);
  width: 9px;
  height: 15px;
}

.icon-erase {
  @extend .css-background-icon;

  background-image: url(/images/erase.png);
  width: 12px;
  height: 12px;
}

.icon-graph-name-edit {
  @extend .css-background-icon;

  background-image: url(/images/graph-name-edit.png);
  width: 15px;
  height: 15px;
}

.icon-help-blue {
  @extend .css-background-icon;

  background-image: url(/images/help-blue.png);
  width: 20px;
  height: 20px;
}

.icon-help {
  @extend .css-background-icon;

  background-image: url(/images/help.png);
  width: 20px;
  height: 20px;
}

.icon-info-gray {
  @extend .css-background-icon;

  background-image: url(/images/info-gray.png);
  width: 16px;
  height: 16px;
}

.icon-left-arrow {
  @extend .css-background-icon;

  background-image: url(/images/left-arrow.png);
  width: 8px;
  height: 13px;
}

.icon-logo-chrome {
  @extend .css-background-icon;

  background-image: url(/images/logo-chrome.png);
  width: 60px;
  height: 60px;
}

.icon-logo-firefox {
  @extend .css-background-icon;

  background-image: url(/images/logo-firefox.png);
  width: 66px;
  height: 60px;
}

.icon-logo-opera {
  @extend .css-background-icon;

  background-image: url(/images/logo-opera.png);
  width: 60px;
  height: 60px;
}

.icon-logo-safari {
  @extend .css-background-icon;

  background-image: url(/images/logo-safari.png);
  width: 60px;
  height: 60px;
}

.icon-notification-error {
  @extend .css-background-icon;

  background-image: url(/images/icon-x.svg);
  width: 14px;
  height: 14px;
}

.icon-rating-disabled-star {
  @extend .css-background-icon;

  background-image: url(/images/rating-disabled-star.png);
  width: 13px;
  height: 13px;
}

.icon-rating-enabled-star {
  @extend .css-background-icon;

  background-image: url(/images/rating-enabled-star.png);
  width: 13px;
  height: 13px;
}

.icon-right-arrow {
  @extend .css-background-icon;

  background-image: url(/images/right-arrow.png);
  width: 8px;
  height: 13px;
}

.icon-search {
  @extend .css-background-icon;

  background-image: url(/images/search.png);
  width: 20px;
  height: 20px;
}

.icon-search:hover {
  @extend .css-background-icon;

  background-image: url(/images/search:hover.png);
  width: 10px;
  height: 10px;
}

.icon-search-active {
  @extend .css-background-icon;

  background-image: url(/images/search-active.png);
  width: 10px;
  height: 10px;
}

.icon-ujet-chat {
  @extend .css-background-icon;

  background-image: url(/images/ujet-chat.png);
  width: 15px;
  height: 16px;
}

// Used as @extend in many .scss files

.icon-order-up-dow {
  @extend .css-background-icon;

  background-image: url(/images/order-up-dow.png);
  width: 6px;
  height: 10px;
}

// Used as @extend in posturas.component.scss

.icon-less-sign {
  @extend .css-background-icon;

  background-image: url(/images/less-sign.png);
  width: 24px;
  height: 24px;
}

// We don't know where this could be used.
// Let's leave it, because -date-picker is a separate module
// and it may be reusing this.

.icon-atm-date-picker {
  @extend .css-background-icon;

  background-image: url(/images/atm-date-picker.png);
  width: 13px;
  height: 14px;
}

// This is both used as a class, and as src in an img element.

.icon-atm-icn-info {
  @extend .css-background-icon;

  background-image: url(/images/atm-icn-info.png);
  width: 20px;
  height: 20px;
}

/** These are used with @extend in globals.scss -------------- */

.icon-b-input-select-active {
  @extend .css-background-icon;

  background-image: url(/images/b-input-select-active.png);
  width: 24px;
  height: 24px;
}

.icon-b-input-select:hover {
  @extend .css-background-icon;

  background-image: url(/images/b-input-select:hover.png);
  width: 24px;
  height: 24px;
}

.icon-b-input-select {
  @extend .css-background-icon;

  background-image: url(/images/b-input-select.png);
  width: 24px;
  height: 24px;
}

.icon-b-inputmini-mas {
  @extend .css-background-icon;

  background-image: url(/images/b-inputmini-mas.png);
  width: 24px;
  height: 24px;
}

.icon-b-inputmini-menos {
  @extend .css-background-icon;

  background-image: url(/images/b-inputmini-menos.png);
  width: 24px;
  height: 24px;
}

.icon-checkbox-active {
  @extend .css-background-icon;

  background-image: url(/images/checkbox-active.png);
  width: 16px;
  height: 16px;
}

.icon-checkbox {
  @extend .css-background-icon;

  background-image: url(/images/checkbox.png);
  width: 16px;
  height: 16px;
}

/* ----------------------------------------------------------- */
