hb-icon {
  // This makes the container "disappear", and makes the children be like
  // they're "one level above" in the DOM.
  display: contents;

  .starman-icon {
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    width: 24px;
    height: 24px;

    &--inverted {
      filter: invert(1);
    }

    /**
     * These icons correspond to Starman icons, and each icon is prefixed
     * with the category it belongs to, written (both the category and the icon)
     * in PascalCase.
     * 
     * For example, the icon `Plus Circled  Line` from the category `User Interface`
     * is set as `UserInterface-PlusCircledLine`. 
     * 
    */

    /**
     * Schedule
     */
    &--Schedule-Pending {
      background-image: url(/images/icons/strmn/schedule/Pending.svg);
    }
    &--Schedule-Clock {
      background-image: url(/images/icons/strmn/schedule/Clock.svg);
    }
    &--Schedule-Calendar {
      background-image: url(/images/icons/strmn/schedule/Calendar.svg);
    }

    /**
     * Information
     */
    &--Information-InfoLine {
      background-image: url(/images/icons/strmn/information/InfoLine.svg);
    }
    &--Information-FaqsFilled {
      background-image: url(/images/icons/strmn/information/FaqsFilled.svg);
    }
    &--Information-AlertFilled {
      background-image: url(/images/icons/strmn/information/AlertFilled.svg);
    }
    &--Information-FaqsLine {
      background-image: url(/images/icons/strmn/information/FaqsLine.svg);
    }
    &--Information-AlertTrianguledFilled {
      background-image: url(/images/icons/strmn/information/AlertTrianguledFilled.svg);
    }
    &--Information-CheckedLine {
      background-image: url(/images/icons/strmn/information/CheckedLine.svg);
    }
    &--Information-CheckedFilled {
      background-image: url(/images/icons/strmn/information/CheckedFilled.svg);
    }
    &--Information-InfoFilled {
      background-image: url(/images/icons/strmn/information/InfoFilled.svg);
    }
    &--Information-AlertLine {
      background-image: url(/images/icons/strmn/information/AlertLine.svg);
    }
    &--Information-AlertLineWhite {
      background-image: url(/images/icons/strmn/information/AlertLineWhite.svg);
    }

    &--Information-AlertTrianguled {
      background-image: url(/images/icons/strmn/information/AlertTrianguled.svg);
    }

    /**
     * Security
     */
    &--Security-Lock {
      background-image: url(/images/icons/strmn/security/Lock.svg);
    }
    &--Security-Unlock {
      background-image: url(/images/icons/strmn/security/Unlock.svg);
    }

    /**
     * Location
     */
    &--Location-Pin {
      background-image: url(/images/icons/strmn/location/Pin.svg);
    }
    &--Location-Globe {
      background-image: url(/images/icons/strmn/location/Globe.svg);
    }

    /**
     * Charts
     */
    &--Charts-Target {
      background-image: url(/images/icons/strmn/charts/Target.svg);
    }
    &--Charts-PieChart {
      background-image: url(/images/icons/strmn/charts/PieChart.svg);
    }
    &--Charts-Bars {
      background-image: url(/images/icons/strmn/charts/Bars.svg);
    }

    /**
     * Objects
     */
    &--Objects-Lifesaver {
      background-image: url(/images/icons/strmn/objects/Lifesaver.svg);
    }
    &--Objects-Gift {
      background-image: url(/images/icons/strmn/objects/Gift.svg);
    }

    /**
     * Profile
     */
    &--Profile-User {
      background-image: url(/images/icons/strmn/profile/User.svg);
    }
    &--Profile-UserCheck {
      background-image: url(/images/icons/strmn/profile/UserCheck.svg);
    }
    &--Profile-Business {
      background-image: url(/images/icons/strmn/profile/Business.svg);
    }
    &--Profile-Users {
      background-image: url(/images/icons/strmn/profile/Users.svg);
    }
    &--Profile-UserPlus {
      background-image: url(/images/icons/strmn/profile/UserPlus.svg);
    }

    /**
     * Finance
     */
    &--Finance-Transfer {
      background-image: url(/images/icons/strmn/finance/Transfer.svg);
    }
    &--Finance-Withdraw {
      background-image: url(/images/icons/strmn/finance/Withdraw.svg);
    }
    &--Finance-Dollar {
      background-image: url(/images/icons/strmn/finance/Dollar.svg);
    }
    &--Finance-Card {
      background-image: url(/images/icons/strmn/finance/Card.svg);
    }
    &--Finance-Deposit {
      background-image: url(/images/icons/strmn/finance/Deposit.svg);
    }

    /**
     * User Interface
     */
    &--UserInterface-Grid {
      background-image: url(/images/icons/strmn/userInterface/Grid.svg);
    }
    &--UserInterface-Search {
      background-image: url(/images/icons/strmn/userInterface/Search.svg);
    }
    &--UserInterface-List {
      background-image: url(/images/icons/strmn/userInterface/List.svg);
    }
    &--UserInterface-ThumbsDown {
      background-image: url(/images/icons/strmn/userInterface/ThumbsDown.svg);
    }
    &--UserInterface-MinusCircledFilled {
      background-image: url(/images/icons/strmn/userInterface/MinusCircledFilled.svg);
    }
    &--UserInterface-Home {
      background-image: url(/images/icons/strmn/userInterface/Home.svg);
    }
    &--UserInterface-LogOut {
      background-image: url(/images/icons/strmn/userInterface/LogOut.svg);
    }
    &--UserInterface-EyeHide {
      background-image: url(/images/icons/strmn/userInterface/EyeHide.svg);
    }
    &--UserInterface-Maximize {
      background-image: url(/images/icons/strmn/userInterface/Maximize.svg);
    }
    &--UserInterface-PlusCircledLine {
      background-image: url(/images/icons/strmn/userInterface/PlusCircledLine.svg);
    }
    &--UserInterface-BellOn {
      background-image: url(/images/icons/strmn/userInterface/BellOn.svg);
    }
    &--UserInterface-DeleteFilled {
      background-image: url(/images/icons/strmn/userInterface/DeleteFilled.svg);
    }
    &--UserInterface-ThumbsUp {
      background-image: url(/images/icons/strmn/userInterface/ThumbsUp.svg);
    }
    &--UserInterface-BellOff {
      background-image: url(/images/icons/strmn/userInterface/BellOff.svg);
    }
    &--UserInterface-Link {
      background-image: url(/images/icons/strmn/userInterface/Link.svg);
    }
    &--UserInterface-Minimize {
      background-image: url(/images/icons/strmn/userInterface/Minimize.svg);
    }
    &--UserInterface-Settings {
      background-image: url(/images/icons/strmn/userInterface/Settings.svg);
    }
    &--UserInterface-Download {
      background-image: url(/images/icons/strmn/userInterface/Download.svg);
    }
    &--UserInterface-PlusCircledFilled {
      background-image: url(/images/icons/strmn/userInterface/PlusCircledFilled.svg);
    }
    &--UserInterface-Layers {
      background-image: url(/images/icons/strmn/userInterface/Layers.svg);
    }
    &--UserInterface-MoreVertical {
      background-image: url(/images/icons/strmn/userInterface/MoreVertical.svg);
    }
    &--UserInterface-CloseCircledFilled {
      background-image: url(/images/icons/strmn/userInterface/CloseCircledFilled.svg);
    }
    &--UserInterface-EditFilled {
      background-image: url(/images/icons/strmn/userInterface/EditFilled.svg);
    }
    &--UserInterface-Plus {
      background-image: url(/images/icons/strmn/userInterface/Plus.svg);
    }
    &--UserInterface-Close {
      background-image: url(/images/icons/strmn/userInterface/Close.svg);
    }
    &--UserInterface-Copy {
      background-image: url(/images/icons/strmn/userInterface/Copy.svg);
    }
    &--UserInterface-Refresh {
      background-image: url(/images/icons/strmn/userInterface/Refresh.svg);
    }
    &--UserInterface-EyeShow {
      background-image: url(/images/icons/strmn/userInterface/EyeShow.svg);
    }
    &--UserInterface-Forbid {
      background-image: url(/images/icons/strmn/userInterface/Forbid.svg);
    }
    &--UserInterface-Fav {
      background-image: url(/images/icons/strmn/userInterface/Fav.svg);
    }
    &--UserInterface-Edit {
      background-image: url(/images/icons/strmn/userInterface/Edit.svg);
    }
    &--UserInterface-MoreHorizontal {
      background-image: url(/images/icons/strmn/userInterface/MoreHorizontal.svg);
    }
    &--UserInterface-Delete {
      background-image: url(/images/icons/strmn/userInterface/Delete.svg);
    }
    &--UserInterface-CloseCircledLine {
      background-image: url(/images/icons/strmn/userInterface/CloseCircledLine.svg);
    }
    &--UserInterface-Filter {
      background-image: url(/images/icons/strmn/userInterface/Filter.svg);
    }
    &--UserInterface-MinusCircled {
      background-image: url(/images/icons/strmn/userInterface/MinusCircled.svg);
    }
    &--UserInterface-Upload {
      background-image: url(/images/icons/strmn/userInterface/Upload.svg);
    }
    &--UserInterface-Minus {
      background-image: url(/images/icons/strmn/userInterface/Minus.svg);
    }

    /**
     * Documents
     */
    &--Documents-Columns {
      background-image: url(/images/icons/strmn/documents/Columns.svg);
    }
    &--Documents-List {
      background-image: url(/images/icons/strmn/documents/List.svg);
    }
    &--Documents-Sheet {
      background-image: url(/images/icons/strmn/documents/Sheet.svg);
    }
    &--Documents-Book {
      background-image: url(/images/icons/strmn/documents/Book.svg);
    }
    &--Documents-Password {
      background-image: url(/images/icons/strmn/documents/Password.svg);
    }

    /**
     * Direction
     */
    &--Direction-ChevronDown {
      background-image: url(/images/icons/strmn/direction/ChevronDown.svg);
    }
    &--Direction-ArrowDown {
      background-image: url(/images/icons/strmn/direction/ArrowDown.svg);
    }
    &--Direction-ArrowRight {
      background-image: url(/images/icons/strmn/direction/ArrowRight.svg);
    }
    &--Direction-ChevronRight {
      background-image: url(/images/icons/strmn/direction/ChevronRight.svg);
    }
    &--Direction-Back {
      background-image: url(/images/icons/strmn/direction/Back.svg);
    }
    &--Direction-Next {
      background-image: url(/images/icons/strmn/direction/Next.svg);
    }
    &--Direction-ArrowUp {
      background-image: url(/images/icons/strmn/direction/ArrowUp.svg);
    }
    &--Direction-ChevronUp {
      background-image: url(/images/icons/strmn/direction/ChevronUp.svg);
    }
    &--Direction-ArrowLeft {
      background-image: url(/images/icons/strmn/direction/ArrowLeft.svg);
    }
    &--Direction-ChevronLeft {
      background-image: url(/images/icons/strmn/direction/ChevronLeft.svg);
    }

    /**
     * Communication
     */
    &--Communication-Smartphone {
      background-image: url(/images/icons/strmn/communication/Smartphone.svg);
    }
    &--Communication-Mail {
      background-image: url(/images/icons/strmn/communication/Mail.svg);
    }
    &--Communication-WifiOff {
      background-image: url(/images/icons/strmn/communication/WifiOff.svg);
    }
    &--Communication-Chat {
      background-image: url(/images/icons/strmn/communication/Chat.svg);
    }
    &--Communication-Phone {
      background-image: url(/images/icons/strmn/communication/Phone.svg);
    }
    &--Communication-PhoneCall {
      background-image: url(/images/icons/strmn/communication/PhoneCall.svg);
    }
    &--Communication-WifiOn {
      background-image: url(/images/icons/strmn/communication/WifiOn.svg);
    }
    &--Communication-Activity {
      background-image: url(/images/icons/strmn/communication/Activity.svg);
    }

    /**
     * Media
     */
    &--Media-VideoOff {
      background-image: url(/images/icons/strmn/media/VideoOff.svg);
    }
    &--Media-PhotoOff {
      background-image: url(/images/icons/strmn/media/PhotoOff.svg);
    }
    &--Media-PhotoOn {
      background-image: url(/images/icons/strmn/media/PhotoOn.svg);
    }
    &--Media-VideoOn {
      background-image: url(/images/icons/strmn/media/VideoOn.svg);
    }
  }
}
