@media only screen and (min-width: 1280px) {

  body,
  html {

    &.onapp {
      overflow-x: hidden;
    }
  }

  .app-component {

    .second-foreign-exchange {
      display: none;
    }

    .third-foreign-exchange {
      display: none;
    }

    .fourth-foreign-exchange {
      display: none;
    }

    .fifth-spacing {
      padding-left: 2px;
    }

    .fifth-index {
      display: none;
    }
  }
}

@media only screen and (max-width: 1280px) {

  body,
  html {

    &.onapp {
      overflow-x: scroll;
    }
  }

  .app-component {
    // View: /main/portfolio

    .overall-portfolio-container {

      .contract-summary {

        .resume .datepicker-container {
          width: auto;
          right: -30px;
        }
      }
    }

    .second-foreign-exchange {
      display: none;
    }

    .third-foreign-exchange {
      display: none;
    }

    .fourth-foreign-exchange {
      display: none;
    }

    .fifth-spacing {
      padding-left: 2px;
    }

    .fifth-index {
      display: none;
    }
  }

  ul {

    &.header-grid {

      li {
        margin: 0 4px;
      }
    }
  }

  .navigation {

    header {

      .nav-secondary {

        .nav-container {

          .items {

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {

  .navigation {

    header {

      .nav-secondary {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {

  .app-component {

    .overall-portfolio-container {

      .top-ten-portfolio,
      .issuedetails-portfolio {
        width: 410px;
        min-width: 397px;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {

  .app-component {
    // Component: Header

    .navigation {

      header {

        .header {

          .row {

            .contract-properties {

              &.session-type {
                margin-left: 50px;
              }
            }

            .bank-properties {

              &.session-type {
                margin-left: 50px;
              }
            }
          }
        }
      }
    }

    // View: /main/portfolio

    .overall-portfolio-container {

      .contract-summary {

        .datepicker-container {
          width: 250px;
          right: -20px;
        }

        .initial-radio {
          margin-right: 10px;
        }

        .mat-radio-label-content {
          font-size: 12px;
        }
      }

      .portfolio-info-container {

        .funds-group,
        .capitals-group {

          .pm-value {

            .side-percent {
              display: block;
              margin: 5px 0 0 15px;
            }
          }
        }
      }
    }

    // View: /main/analisis/gbm

    .gbm-analysis-tables-container {

      .gbm-analysis {
        width: 100%;
      }

      .top-picks {
        display: block;

        .top-picks-large,
        .top-picks-sm-md {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }

  ul {

    &.header-grid {

      li {
        margin: 0 4px;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {

  .app-component {
    // View: /main/operacion

    .operation-container {
      // .grid-monitor {
      //   width: 60%;
      //   float: left;
      // }

      .grid-graph-issue {
        // width: 40%;
        // float: right;
        // padding: 0;
        // margin-right: -8px;

        .grid-graph {
          width: 100%;
        }

        // .grid-issues {
        //   .grid-detail {
        //     width: 100%;
        //   }

        //   .grid-shortsell {
        //     width: 100%;
        //   }
        // }
      }
    }

    .second-foreign-exchange {
      display: block;
    }

    .third-foreign-exchange {
      display: none;
    }

    .fourth-foreign-exchange {
      display: none;
    }

    .fifth-spacing {
      padding-left: 2px;
    }

    .fifth-index {
      display: none;
    }
  }
}

@media only screen and (min-width: 1600px) {

  .app-component {

    .second-foreign-exchange {
      display: block;
    }

    .third-foreign-exchange {
      display: none;
    }

    .fourth-foreign-exchange {
      display: none;
    }

    .fifth-spacing {
      padding-left: 17px;
    }

    .fifth-index {
      display: block;
    }
  }
}

@media only screen and (min-width: 1800px) {

  .app-component {

    .second-foreign-exchange {
      display: block;
    }

    .third-foreign-exchange {
      display: block;
    }

    .fourth-foreign-exchange {
      display: block;
    }

    .fifth-spacing {
      padding-left: 17px;
    }

    .fifth-index {
      display: block;
    }
  }
}

@media only screen and (min-width: 1980px) {

  .app-component {
  }
}

@media only screen and (min-width: 2430px) {

  .app-component {
    // View: /main/operacion

    .operation-container {

      &.big-screen {

        .grid-monitor {
          height: 100%;
        }

        .grid-graph-issue {
          height: auto;

          .grid-graph {
            height: 50%;
          }

          .grid-issues {
            height: calc(50% - 15px);

            .grid-detail,
            .grid-shortsell {
              height: 100%;
            }
          }
        }
      }

      .grid-monitor {
        // width: 100%;
        // float: left;

        &.fullscreen {
          float: none;
          display: block;
        }
      }

      .grid-graph-issue {
        // width: 100%;
        // float: right;
        // padding: 0;
        // margin-right: -8px;

        &.fullscreen {
          float: none;
          display: block;
          margin-right: 0;
        }

        .grid-graph {
          width: 100%;
        }

        .grid-issues {

          .grid-detail {
            width: 49%;
          }

          .grid-shortsell {
            width: 49%;
          }
        }
      }
    }
  }
}
