@import 'settings';
@import 'icons';
@import '_typography';

.mat-datepicker-content {
  border-radius: 8px !important;
  box-shadow: 0 20px 30px 0 $datepicker-shadow !important;
  border: solid 1px $datepicker-border;
  background-color: $white;
}

.datepicker-container {
  display: inline-flex;

  .mat-form-field {
    width: 126px;
    height: 40px;
    margin-right: 18px;

    &.mat-input-container {
      cursor: pointer;
    }

    input {
      cursor: pointer;
    }

    .mat-form-field-underline {
      top: 43px;
      opacity: 0;
    }
  }

  .mat-form-field-infix {
    display: flex;
    width: 150px;
    font-size: 14px;
    padding-top: 7px;
    padding-left: 8px;
    border: solid 1px $calendar-border;
    border-radius: 4px;
    background-color: transparent;
    height: 40px;

    .parsed-date {
      color: $calendar-text;
      margin-top: 5px;
      width: 140px;
      cursor: pointer;
    }

    .mat-input-placeholder,
    .mat-form-field-label {
      padding: 0;
      font-size: 14px !important;
      color: $black;
    }

    .mat-form-field-placeholder-wrapper {
      overflow: visible;

      .mat-input-placeholder {
        left: inherit;
        margin-top: -5px;
      }
    }

    .mat-form-field-label-wrapper,
    .mat-form-field-label-wrapper {
      font-family: $font-worksans;
      top: -20px;
      font-size: 14px;
      color: $black;
    }

    .calendar-arrow {
      width: 6px;
      height: 3px;
      position: relative;
      right: 10px;
      top: 10px;
    }
  }

  .mat-form-field-label {
    margin-top: 0 !important;
    padding-top: 0;
    color: $black;
    font-size: 14px !important;
  }

  .mat-focused {

    .mat-form-field-ripple {
      background-color: transparent;
    }
  }

  .icon-atm-date-picker {
    opacity: 0.5;
    margin-top: 4px;
    width: 14px;
  }

  .mat-input-placeholder {
    left: 8px;
    margin-top: 5px;
  }
}

.mat-calendar {
  font-family: $font-worksans;
  height: 100% !important;

  .mat-calendar-header {
    margin-top: -2px;
    height: 40px;
    padding: 0;
    background-color: $calendar-background;
    border: solid 1px $calendar-border;
    border-radius: 8px 8px 0 0;

    .mat-calendar-controls {
      margin: 0;
    }

    .interval-warning {
      text-align: center;
      margin: 0;
      font-size: 10px;
      margin-top: 10px;
    }
  }

  .mat-calendar-content {
    padding: 16px 8px 8px;
  }

  table {

    thead {

      tr {
        font-size: 14px;
        color: $calendar-border;
      }

      .mat-calendar-table-header-divider {
        display: none;
      }
    }

    tbody {

      tr[aria-hidden] {
        display: none;
      }

      td[aria-hidden] {
        visibility: hidden;
      }

      .mat-calendar-body-selected {
        background-color: $calendar-border;
      }

      .mat-calendar-body-today {
        border-color: $calendar-border;
      }
    }
  }
}

.mat-button,
.mat-form-field {
  font-family: $font-worksans !important;
}
