@import 'settings';
@import 'icons';
@import 'typography';

.table-grid-nvo {
  font-family: $font-worksans;
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  line-height: 1;
  // min-height: 410px;
  max-height: 1000px;
  overflow-x: auto;
  text-align: center;
  width: 100%;

  ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    width: 100%;
    height: 35px;
    margin: 0;
    line-height: 35px;

    li {
      width: 8%;
      display: inline-block;
      position: relative;
      margin: 0 8px;
      overflow: hidden;

      &.li-text-format {
        text-align: left;
      }

      &.li-number-format {
        text-align: right;
      }

      &.li-issue-format {
        font-weight: 500;
        text-align: left;

        &.long-text {
          width: 10.5%;
          min-width: 10.5%;
        }
      }

      &.treport {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;

        a {
          font-size: 11px;
        }
      }

      &.ratio-bar {
        overflow: visible;

        .ratio-content {
          margin-top: 13px;

          span {
            margin-top: -14px;
          }
        }
      }

      .gbmRanking {
        margin-top: 7px;
      }

      .checkbox {
        margin-top: 9px;
      }

      .tr-trend-graph-flag {
        display: block;
      }
    }

    &.header-grid {
      min-height: 27px;
      background-color: $regular-header-color;
      line-height: 12px;

      li {
        display: flex;
        justify-content: center;
        height: 35px;
        line-height: initial;
        flex-direction: column;
        font-weight: 600;
        color: $black;

        .wrapper {
          display: inline-flex;
        }
      }
    }

    &.row-grid {
      border-bottom: solid 1px $gris-5;
      cursor: pointer;
    }
  }

  .sort-icon {
    @extend.icon-order-up-dow;

    display: inline-flex;
    margin-left: 5px;
  }
}

.search-NoFound {
  font-family: Work sans, 'Helvetica Neue', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  letter-spacing: 0.4px;
  color: $gris-2;
  margin-bottom: 40px;
  margin-top: 40px;
  padding-top: 60px;
}

.body-grid {
  overflow-y: auto;
  max-height: 689px;
  min-height: 130px;
}

.body-grid::-webkit-scrollbar {
  width: 6px;
}

.body-grid::-webkit-scrollbar-track {
  box-shadow: inset none none 15px $white;
  border-radius: 10px;
}

.body-grid::-webkit-scrollbar-thumb {
  color: $white;
  background: $gris-3;
  border-radius: 10px;
}

.body-grid::-webkit-scrollbar-thumb:hover {
  background: $gris-2;
}
