/* VARIABLES */

// Colores Principales
$black: #000000;
$darkjungle: #212121;
$gris-0: #141414;
$gris-1: #3a3b33;
$gris-2: #797979;
$gris-3: #aaaaaa;
$gris-4: #d2d2d2;
$gris-5: #efefef;
$gris-6: #f6f8fb;
$gris-7: #191919;
$gris-8: #666666;
$gris-9: #3d3834;
$gris-10: #f7f9fa;
$gris-11: #e4e4e4;
$gris-12: #dddddd;
$gris-13: #e9ecef;
$gris-14: #afafaf;
$gris-15: #eeeeee;
$gris-16: #f2f2f2;
$gris-17: #767676;
$gris-18: #d7dce2;
$gris-19: #f8f8ff;
$gris-20: #8d8d8d;
$gris-21: #f3f3f3;
$white: #ffffff;
$pale-grey: #f4f6f9;
$blackshadow: rgba(0, 0, 0, 0.1);
$box-shadow: rgba(217, 225, 231, 0.2);
$gray-scroll: #d4d9e0;
$color-secondary-green: #00ce7a;

$medium: #96eb51;
$medium-obscuro: #83ce45;
$medium-claro: #9ee2d6;

$kiwi: #37a08c;
$kiwi-obscuro: #6dd319;
$kiwi-claro: #4adf99;
$kiwi-variant-1: #96eb51;

$guppie: #41e097;
$guppie-obscuro: #1eb771;
$guppie-claro: #83ebbb;

$raspberry: #ef1359;
$raspberry-obscuro: #c20d47;
$raspberry-claro: #f45b8b;

$positive: #1f883d;
$positive-high-contrast: #00ce7a;
$negative: #da1e28;
$negative-high-contrast: #ff3b30;

$azure: #00a9ff;
$azure-obscuro: #0087cc;
$azure-claro: #4dc3ff;

$blueinfo: #def5ff;

$risk-bar: #f7f9fa;
$low-risk: #26bd5a;
$medium-risk: #feb900;
$high-risk: #ed6347;

$skeleton-gray: #eef0f3;

/* Portfolio */
$hb-gray-01: #cbd8e3;
$hb-gray-02: #fdfdfe;
$hb-gray-03: #8993a4;
$hb-gray-04: #f3f3f7;
$hb-gray-05: #adb7c4;
$hb-gray-06: #f5f6f8;
$hb-negative: #f55e4b;

/* Navigation */
$hb-green: #96eb51;
$border-gray-1: #ccd4dc;
$border-gray-2: #939da6;
$gray-shadow: rgba(0, 0, 0, 0.02);

/* Portfolio */
$hb-gray-01: #cbd8e3;
$hb-gray-02: #fdfdfe;
$hb-gray-03: #8993a4;
$hb-gray-04: #7d8387;
$hb-negative: #f55e4b;

/* Muñeco */
$border-gray-3: #a8a8a8;
$widget-title: $black;
$search-text-gray-1: #6e7684;
$border-gray-4: #a8a8a8;
$border-blue: #026afa;
$blue-background: #ecf5fe;
$border-red: #f70017;
$red-background: #fff3f3;
$gray-text: #c1c7d0;
$gray-background: #fafbfc;

/* Colores Extras */
$oro: #b6a269;
$plata: #7d868c;
$bronce: #97785d;

// Tipografia
$ft-regular: 400;
$ft-medium: 500;
$ft-bold: 700;
$ft-black: 900;
$hover-positive: #b3e7de;
$hover-negative: #f9bace;
$hover-neutro: #efefef;
$opcion-1: #83ce45;
$opcion-2: #96eb51;
$opcion-3: #b7ef8a;
$opcion-4: #d2f4b6;
$textos-sube: #3d9f8c;
$textos-baja: $raspberry;

// Muñeco

/**
 * If you want to update the venta/compra colors, also update the
 * buyVolume, buyPrice, sellVolume, and sellPrice columns in
 * src/components/market/monitor/monitor.component.ts
 */
$blue-venta: #444ee6;
$red-compra: #da1e28;
$bg-venta: #faeded;
$bg-compra: #f0f6ff;

// Date-picker
$calendar-border: #8d8d8d;
$calendar-background: #f3f3f3;
$calendar-text: $black;
$calendar-background-active: #b7d3fd;
$datepicker-border: #c4cdd5;
$datepicker-shadow: rgba(228, 232, 237, 0.5);

/* new dashboard colors */
$nd-gray: #8993a3;
$nd-lightgray: #f5f6f8;

/* add card */
$blue-card: #f3f9ff;
$white-e4e8ed: #e4e8ed;
$gris-16: #e5e5e5;
$gris-17: #d5d5d5;
$green-card: #e6f9f1;
$green-ligth: #e6f6eb;
$error-File: #faeded;
$card-text: #f5f5f5;
$card-1: #3d4144;
$card-2: #262729;
$card-3: #0d0e0e;
$card-4: #d9e1e7;
$card-5: #fef8e6;
$card-6: #fcedee;

.chart-op-1 {
  background-color: $opcion-1;
}

.chart-op-2 {
  background-color: $opcion-2;
}

.chart-op-3 {
  background-color: $opcion-3;
}

.chart-op-4 {
  background-color: $opcion-4;
}

.chart-subset-1 {
  background-color: $gris-2;
}

.chart-subset-2 {
  background-color: $gris-4;
}

.chart-op-down {
  background-color: $textos-baja;
}

.up {
  color: $textos-sube !important;
}

.down {
  color: $textos-baja !important;
}

$max-grid: 1330px;

$regular-header-color: $gris-21;

// Secondary Colors
$color-secondary-human: #fdede2;
