$black-three: #262626;
$black-two: #393939;
$black: #000000;
$color-primary-black: $black;
$brown-grey-two: #8d8d8d;
$brown-grey: #a8a8a8;
$brownish-grey: #6f6f6f;
$green-blue: #00ce7a;
$greyish-brown: #525252;
$very-light-pink-four: #c6c6c6;
$very-light-pink-three: #e0e0e0;
$very-light-pink: #fdede2;
$warm-blue: #444ee6;
$white: #ffffff;
$color-primary-white: $white;
$bright-blue: #026afa;
$battleship-grey-two: #6e7684;
$bluey-grey-two: #adb7c4;
$pale-grey-ten: #f0f6ff;
$white-smoke: #f3f3f3;

// Undefined colors
$black-four: #121212;

// V1 Guidelines colors
$steel-color: #7d8387;

// V2 Guidelines colors
$color-alert-error-light: rgba(218, 30, 40, 0.05);
$color-alert-error: #da1e28;
$color-alert-success-light: rgba(36, 161, 72, 0.1);
$color-alert-success: #1f883d;
$color-alert-warning-light: rgba(241, 194, 27, 0.1);
$color-alert-warning: #f1c21b;
$color-bg-alert-info: rgba(68, 78, 230, 0.08);
$color-blue100: #f0f0ff;
$color-g01: #f3f3f3;
$color-g03: #c6c6c6;
$color-g04: #a8a8a8;
$brown-grey: $color-g04;
$color-g06: #6f6f6f;
$color-g07: #525252;
$greyish-brown: $color-g07;
$color-g09: #262626;
$color-grey: #393939;
$color-lightgrey: #8d8d8d;
$color-secondary-blue: #444ee6;
$color-secondary-green: #00ce7a;
$color-secondary-human: #fdede2;
$color-silver: #e0e0e0;
$color-ghost-white: #f0f1fe;
$color-solitude: #e6f6eb;
$color-old-lace: #fef8e6;
$color-amour: #fcedee;

// Extended $blue
$dark: #2a324b;

// Buttons state colors
$pale-grey-three: #e4e8ed;
