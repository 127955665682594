@import '../color-palette';
@import '../mixins';
@import 'src/style/typography';

.gbm-filters {
  display: flex;
  margin-bottom: 32px;

  &__dates {
    margin: 8px 40px 48px;
    font-size: 12px;
  }

  &__date {
    display: inline-block;
    padding: 0.3rem 0.9rem;
    background: $white;
    color: $black;
    cursor: pointer;
    font-weight: $gbm-bold;
    letter-spacing: 1px;
    line-height: 1;
    vertical-align: top;

    &--active,
    &:hover,
    &:focus {
      background: $bright-blue;
      color: $white;
    }
  }

  &__select {
    display: inline-block;
    padding: 0.3rem 0.9rem;
    font-weight: $gbm-bold;
    vertical-align: top;

    &__title {
      font-size: 10px;
      font-weight: $gbm-medium;
      letter-spacing: 1px;
    }

    &__content {
      font-size: 14px;
    }
  }

  &__date + &__date,
  &__date + &__select,
  &__select + &__select,
  &__dates + &__select {
    margin-left: 1rem;
  }

  &__movement-title {
    font-size: 14px;
  }

  &__list {
    display: flex;
    width: 570px;
    height: 40px;
    align-items: center;
    justify-content: left;
    padding: 0;
    margin: 8px 0;
    color: $battleship-grey-two;
    font-size: 14px;
    list-style-type: none;
    font-family: $font-worksans;

    li {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      border: 1px solid $bluey-grey-two;
      margin-right: -1px;

      &:first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }

      button {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      &.active {
        z-index: 1;
        border: 1px solid $bright-blue;
        background-color: $pale-grey-ten;

        button {
          color: $bright-blue;
        }
      }
    }
  }
}
