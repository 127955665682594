@import 'settings';
@import 'color-palette';
@import 'typography';
@import 'guidelines/index';
@import 'icons';

/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable number-leading-zero */
/* stylelint-disable at-rule-empty-line-before */

.input-money {
  display: block;
  border: solid 1px $border-gray-3;
  border-radius: 4px;
  padding: 10px 15px;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  outline: none;

  * {
    box-sizing: border-box;
  }

  &.invalid {
    color: $raspberry;

    .titles-value {
      color: $raspberry;
    }
  }

  &.align-left {

    label,
    .titles-value {
      text-align: left;
    }

    .titles-value {
      padding-left: 12px;

      &:focus,
      &:valid {

        ~ label {
          padding-left: 0;
          left: -3px;
        }
      }
    }
  }

  &.active {
    border-color: black;
  }

  .titles-value {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    height: 100%;
    border-radius: 4px;
    width: 100%;
    border: 0;
    background: transparent;
    z-index: 1;

    &:focus,
    &:valid {

      ~ .placeholder {
        top: -30px;
        display: none;
      }
    }
  }

  .placeholder {
    position: relative;
    width: 100%;
    display: block;
    text-transform: capitalize;
    font-size: 14px;
    color: $color-g06;
    top: 3px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    z-index: 0;
  }

  .stepper {
    background: black;
    border: 1px solid black;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    width: 146px;
    position: absolute;
    right: -1px;
    box-sizing: content-box;
    top: -1px;
    color: white;
    display: flex;
    z-index: 2;
    overflow: hidden;

    // We use * instead of button so it's less specific and
    // --add and --substract take priority over these rules
    // which only act as a reset.

    > * {
      width: 50%;
      border: none;
      background: black;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 0;
    }

    &--add {
      border-left: 1px solid rgba($white, 0.15);
      background-image: url('/style/assets/atm-icn-plus.svg');
      background-size: 10px 10px;
    }

    &--substract {
      background-image: url('/style/assets/atm-icn-substract.svg');
      background-size: 10px 3px;
    }

    &.disabled {
      border-color: $color-lightgrey;

      button {
        border-color: transparent;
        background-color: $color-lightgrey;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

.punch-picker {
  border: solid 1px $border-gray-3;
  height: 40px;
  width: 132px;
  border-radius: 4px;
  color: $darkjungle;
  box-sizing: border-box;
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;

  &.invalid {
    border: solid 1px $raspberry;
  }

  &.active {
    border-color: $black;
  }

  * {
    box-sizing: border-box;
  }

  > div {
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: $gris-0;
    }

    &.minus {
      float: left;
      max-width: 35px;
      width: 20%;
      text-align: center;
      height: 38px;
      line-height: 40px;
      position: relative;
      z-index: 2;
      border-right: solid 1px $border-gray-3;
      background: $gray-background;
      border-radius: 5px 0 0 5px;

      &::before {
        content: '';
        text-align: center;
        margin: 8px auto 0;
        @extend .icon-b-inputmini-menos;

        box-sizing: border-box;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
      }
    }

    &.plus {
      float: right;
      max-width: 35px;
      width: 20%;
      text-align: center;
      height: 38px;
      line-height: 40px;
      position: relative;
      z-index: 2;
      border-left: solid 1px $border-gray-3;
      background: $gray-background;
      border-radius: 0 5px 5px 0;

      &::before {
        content: '';
        text-align: center;
        margin: 8px auto 0;
        @extend .icon-b-inputmini-mas;

        box-sizing: border-box;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
      }
    }
  }

  .titles-value {
    background: transparent;
    border: 0;
    width: 100%;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $darkjungle;
    outline: none;
    position: absolute;
    left: 0;
    z-index: 1;

    &:valid {
      font-weight: 600;
      font-size: 15px;
    }
  }

  label {
    text-align: center;
    position: absolute;
    top: 14px;
    width: 100%;
    display: block;
    text-transform: capitalize;
    font-size: 14px;
    color: $gris-3;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    z-index: 0;
  }
}

button {
  outline: none;
  font-family: $font-worksans;

  &.btn-compra,
  &.btn-shortsale,
  &.btn-venta,
  &.btn-retiro,
  &.btn-cancel,
  &.btn-movement {
    width: 160px;
    background: $gris-14;
    color: $white;
    height: 56px;
    border: $white solid 0.1px;
    font-size: 16px;
    font-weight: 700;
    font-family: $font-worksans;
    transition: background-color 0.3s cubic-bezier(0.694, 0, 0.335, 1),
      color 0.3s cubic-bezier(0.694, 0, 0.335, 1),
      opacity 0.3s cubic-bezier(0.694, 0, 0.335, 1);
    cursor: pointer;

    &.active {
      color: $white;
    }
  }

  &:hover {
    transition: background-color 0.3s cubic-bezier(0.694, 0, 0.335, 1),
      color 0.3s cubic-bezier(0.694, 0, 0.335, 1),
      opacity 0.3s cubic-bezier(0.694, 0, 0.335, 1);
  }

  &.btn-venta {

    &.active {
      background: $red-compra;
      color: $white;
    }
  }

  &.btn-compra {

    &.active {
      background: $blue-venta;
      color: $white;
    }
  }

  &.btn-shortsale {

    &.active {
      background: $red-background;
      border: $border-red solid 1px;
      color: $border-red;
    }
  }

  &.btn-retiro {

    &.active {
      background: $darkjungle;
    }
  }

  &.btn-cancel {
    background: transparent;
    border: solid 1px $darkjungle;
    color: $darkjungle;
    font-weight: $gbm-black;

    &:hover {
      background: $darkjungle;
      color: $white;
    }

    &.disabled {
      background: transparent;
      color: $gris-2;
      border: solid 1px $gris-2;
      cursor: default;
    }
  }

  &.btn-movement {
    background: $darkjungle;
    color: $white;

    &:disabled,
    &.disabled {
      background: transparent;
      color: $gris-2;
      border: solid 1px $gris-2;
      cursor: default;
    }

    &:not(.disabled) {

      &:hover {
        background: $gris-9;
      }
    }
  }
}

.checkbox {
  display: inline-block;
  text-align: center;

  .label-check {
    font-size: 14px;
    color: $darkjungle;
    line-height: 20px;
  }

  .square {
    // width: 16px;
    // height: 16px;
    // display: inline-block;
    margin-right: 10px;
    // border: 2px solid $medium;
    // border-radius: 4px;
    float: left;
    @extend .icon-checkbox;
  }

  .input-check {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {

      + .label-check {

        .square {
          @extend .icon-checkbox-active;
          // background: $medium;

          // &::after {
          //   content: '\2713';
          //   color: $white;
          //   font-size: 15px;
          //   text-align: center;
          //   font-weight: 100;
          //   margin-left: 0;
          //   margin-top: -3px;
          //   position: absolute;
          // }
        }
      }
    }
  }
}

.tabs {
  // grid flex
  display: inline-block;
  height: 45px;
  width: 100%;
  margin: 20px 0;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;

  &.pointed {

    li {
      background-color: transparent;
      border: none;
      position: relative;
      font-weight: normal;

      &:last-of-type {

        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        background-color: $medium;
        right: 0;
        position: absolute;
        border-radius: 5px;
        top: 20px;
      }

      &.active {
        font-weight: 700;
        background-color: transparent;
      }
    }
  }

  li {
    height: 45px;
    list-style: none;
    float: left;
    text-align: center;
    border: 1px solid $medium;
    border-radius: 0;
    box-sizing: border-box;
    color: $darkjungle;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 45px;

    &:first-child {
      border-radius: 4px 0 0 4px;

      &:nth-child(1) {
        width: 100%;
      }

      &:nth-last-child(2),
      &:nth-last-child(2) ~ li {
        width: 50%;
      }

      &:nth-last-child(3),
      &:nth-last-child(3) ~ li {
        width: 33.33%;
      }
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.active {
      background-color: $medium;
    }
  }

  &.square {
    border-bottom: solid 1px $border-gray-4;
    height: 40px;

    li {
      height: 40px;
      padding: 0 8px;
      cursor: pointer;
      box-sizing: border-box;
      // min-width: 160px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      width: 14%;
      border: 0;
      color: $black;
      min-width: auto;
      overflow: hidden;
      font-size: 14px;
      font-weight: normal;

      span {
        cursor: pointer;
        position: absolute;
        right: 0;
      }

      .edit {
        width: 100px;
        font-size: 12px;
        text-align: center;
        border: 1px solid $gris-3;
        color: $gris-3;
        padding: 5px;
        border-radius: 4px;
      }

      &.add-more {

        .icon {
          float: left;
          margin-right: 5px;
          cursor: pointer;
        }

        label {
          float: left;
          cursor: pointer;
        }
      }

      &.active-drop {
        border: solid 1px $gris-3;
        border-radius: 4px;
        color: $gris-3;
      }

      &.selected {
        position: relative;
        font-weight: $gbm-black;

        &::after {
          border-bottom: solid 1px $black;
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 1px;
          left: 0;
          transition: all ease 200ms;
        }
      }
    }
  }
}

.modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 103;

  * {
    box-sizing: border-box;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
  }

  .container {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 10px 0 rgba(207, 199, 199, 0.2);
    border-radius: 4px;
    width: 480px;
    height: auto;
    padding: 58px 48px;
    background: $white;
    font-size: 12px;
    z-index: 101;

    &.ventaencorto {
      top: 10%;
    }

    .row {
      display: inline-block;
      width: 100%;
      position: relative;

      &.title {
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 42px;
        position: relative;
        left: -48px;
        padding-left: 46px;
        line-height: 25px;

        &.retiro {
          border-left: $medium solid 5px;
        }

        &.compra {
          border-left: $blue-venta solid 5px;
        }

        &.venta {
          border-left: $red-compra solid 5px;
        }
      }

      &.issue {
        padding-bottom: 24px;
        margin-bottom: 26px;
        border-bottom: solid 2px $gris-2;

        .issue-title {
          font-size: 10px;
          font-weight: $gbm-black;
          color: $gris-2;
          margin-bottom: 8px;
        }

        .issue-subtitle {
          font-size: 18px;
          font-weight: 500;
          color: $darkjungle;
        }
      }

      &.help-message {
        background: $blueinfo;
        color: $azure;
        border-radius: 4px;
        width: 100%;
        padding: 22px;
        margin-top: 24px;

        .left {
          width: 32px;
          padding: 0;
        }

        .right {
          width: 307px;
          font-size: 12px;
          line-height: 1.5em;
          font-weight: 100;
          padding: 0;
          float: left;
          text-align: justify;

          a {
            font-size: 12px;
            line-height: 1.5em;
            font-weight: 500;
            text-decoration: underline;
            display: inline;
          }

          span {
            font-size: 12px;
            line-height: 1.5em;
            font-weight: 500;
          }
        }

        &.spei {
          margin-top: 17px;
        }
      }

      &.token-request {
        margin-top: 16px;

        .token {
          width: 170px;
        }
      }

      &.spei-text {
        font-size: 16px;
        text-align: justify;
      }

      &.mfa-message {
        font-weight: $gbm-black;
        margin-top: 22px;
        line-height: 18px;

        &.banxico-text {
          font-weight: normal;
          margin-top: 0;
          font-size: 16px;
          margin-bottom: 18px;
          line-height: 20px;

          &.top-too {
            margin-top: 24px;
          }
        }
      }
    }

    .left {
      float: left;
      text-align: right;
      padding-right: 10px;
      line-height: 25px;

      &.title {
        font-weight: $gbm-black;
        margin-top: 18px;
      }
    }

    .right {
      padding-left: 10px;
      float: right;
      text-align: left;
      line-height: 25px;
      font-weight: $gbm-black;
    }

    .err-msg {
      color: $raspberry;
    }
  }

  .actions {
    width: 100%;
    display: inline-block;
    margin-top: 38px;

    button {
      float: left;

      &:last-of-type {
        float: right;
      }
    }
  }

  .close-link {
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 500;
  }
}

.simple-input {
  position: relative;
  width: 237px;
  min-height: 45px;
  margin-bottom: 10px;
  margin-top: 1px;
  z-index: 5;

  &.passfrase {

    .visible {
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }

  input {
    float: left;
    position: relative;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 8px;
    display: block;
    color: $darkjungle;
    border: 1px solid $darkjungle;
    border-radius: 4px;
    box-sizing: border-box;

    &.invalid {
      border: 1px solid $raspberry;

      &:focus,
      &:valid {
        border: 1px solid $raspberry;
      }
    }

    &.success {
      border: 1px solid $medium;

      &:focus,
      &:valid {
        border: 1px solid $medium;
      }
    }
  }

  input:focus,
  input:valid {
    outline: none; // border: 1px solid $medium;
    line-height: 30px;
  }

  label {
    width: 100%;
    display: block;
    color: $gris-3;
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 13px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: 5px;
    left: 15px;
    color: $darkjungle;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  input:focus,
  input:valid {
    line-height: normal;
    padding: 15px 10px 0 15px;
  }
}

.search {
  width: 468px;
  height: 40px;
  margin-bottom: 35px;
  margin-top: 10px;
  float: none;
  position: relative;
  margin-left: 10px;
  font-size: 14px;
  color: $search-text-gray-1;
  font-weight: 500;
  line-height: 1;

  input {
    width: 100%;
    height: 40px;
    padding: 10px;
    display: block;
    border: none;
    border: solid 1px $border-gray-3;
    border-radius: 4px;
    box-sizing: border-box;
    background-image: url(/images/icons/icn-search.svg);
    background-size: 24px;
    background-position: 97% 7px;
    background-repeat: no-repeat;

    &.filled {
      background-image: none;
    }
  }

  input {

    &.ng-pristine {
      box-shadow: none !important;
    }

    &.ng-invalid-required {
      box-shadow: none !important;
    }
  }

  input:focus {
    outline: none;
    border: 1px solid $border-gray-3;
  }

  label {
    width: 100%;
    display: block;
    color: $gris-2;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 13px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  input:focus ~ label,
  input:valid ~ label {
    display: none;
    // top: 7px;
    // left: 15px;
    // color: $darkjungle;
    // font-size: 10px;
    // font-weight: 400;
    // letter-spacing: 0.5px;
    // text-transform: capitalize;
  }

  input:focus,
  input:valid {
    // padding: 15px 10px 0 15px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }
}

.rounded-tab {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  background: $medium;
  border-radius: 100px;

  .switch-option {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    font-size: 12px;

    &.active {
      color: #ffffff;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
}

.switch input {
  display: none;
}

.switch-option {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;

  &.active {
    color: #ffffff;
    font-weight: 500;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 21px;
  width: 50%;
  left: 5px;
  bottom: 5px;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input + .slider,
input:checked + .slider {
  background-color: $darkjungle;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider::before {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.slider.round {
  border-radius: 100px;
}

.slider.round::before {
  border-radius: 100px;
}

.switch-slide {
  width: auto;
  height: 30px;
  background: $gris-10;
  border-radius: 100px;
  display: block;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .slide-container {
    width: 100%;
    display: inline-block;
    height: 30px;
    position: relative;

    .slide {
      position: absolute;
      background: $white;
      border-radius: 100px;
      height: 23px;
      width: 50%;
      top: 3px;
      left: 0;
      transition: all ease 200ms;
      transform: translate(2%, 0);
      box-shadow: 0 1px 7px -1px $nd-gray;
    }

    .option {
      float: left;
      width: 50%;
      border-radius: 100px;
      color: $nd-gray;
      height: 100%;
      line-height: 30px;
      text-align: center;
      position: relative;
      z-index: 2;
      font-weight: 700;
      font-size: 13px;
      cursor: pointer;

      &.active {
        color: $darkjungle;

        &:first-child {

          ~ .slide {
            transform: translate(2%, 0);
            transition: all ease 200ms;
          }
        }

        &:last-of-type {

          ~ .slide {
            transform: translate(98%, 0);
            transition: all ease 200ms;
          }
        }
      }
    }

    &.three {

      .slide {
        width: 33.33%;
      }

      .option {
        width: 33.33%;

        &.active {

          &:first-child {

            ~ .slide {
              transform: translate(2%, 0);
              transition: all ease 200ms;
            }
          }

          &:nth-child(2) {

            ~ .slide {
              transform: translate(100%, 0);
              transition: all ease 200ms;
            }
          }

          &:last-of-type {

            ~ .slide {
              transform: translate(198%, 0);
              right: 0;
              transition: all ease 200ms;
            }
          }
        }
      }
    }
  }
}

.unscrollable {
  overflow-y: hidden;
  overflow-x: auto;
}

.unclickable {
  pointer-events: none !important;
  scroll-behavior: unset;
}

.radiobox {
  width: auto;
  color: $medium;
  display: inline-block;
  font-size: 12px;
  pointer-events: all;
  margin-top: 12px;

  &.active {

    input[type='radio'] {

      + .icon {
        opacity: 1;
      }
    }
  }

  input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;

    + .icon {
      width: 18px;
      height: 18px;
      border: solid 1px $darkjungle;
      border-radius: 18px;
      float: left;
    }

    &:checked {

      + .icon {
        opacity: 1;
        box-shadow: none;
        border: solid 5px $search-text-gray-1;
      }
    }
  }

  .text {
    color: $darkjungle;
    font-weight: 500;
    display: inline-block;
    margin-left: 5px;
    line-height: 18px;
  }
}

.belt {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding-left: 5px;
  font-size: 13px;

  li {
    display: flex;
    height: 20px;
    width: 35px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 10px;
    cursor: pointer;

    &.selected {
      background: $black;
      color: $white;
      cursor: default;
    }

    &.disabled {
      color: $gris-3;
      cursor: default;
    }
  }

  &-pills {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    padding: 0;

    li {
      margin-right: 16px;
    }
  }
}

$color-highlight: lighten($skeleton-gray, 7%);

.skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 25%;
  border-radius: 4px;
  height: 8px;
  background: $skeleton-gray;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      $skeleton-gray,
      $color-highlight,
      $skeleton-gray
    );
    animation: progress 1s ease-in-out infinite;
  }
}

.white-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 25%;
  border-radius: 4px;
  height: 8px;
  background: $white;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $white, $skeleton-gray, $white);
    animation: progress 1s ease-in-out infinite;
  }
}

@keyframes progress {

  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.help-blocked-section {
  background: $blueinfo;
  color: $azure;
  border-radius: 4px;
  width: 100%;
  padding: 10px;

  .left {
    float: left;
    margin: 12px 10px 0 0;
  }

  .right {
    font-size: 14px;
    display: inline-block;
    line-height: 46px;
  }
}

.NoneRows {
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .NoneRows__message {
    margin-top: 9px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.information-card {
  width: 100%;
  min-width: 352px;
  min-height: 48px;
  border-radius: 4px;
  background-color: $color-ghost-white;

  &__container {
    height: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
  }

  &__leading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 16px;

    &--bell {
      background-image: url(/images/icons/atm-icn-bell.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }

    &--info {
      background-image: url(/images/icons/atm-icn-alert-info.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__information {
    flex: 1;
    font-size: 12px;
    font-weight: $gbm-medium;
    line-height: 20px;

    strong {
      font-weight: $gbm-extrabold;
    }

    p {
      font-weight: $gbm-regular;
      margin: 0;
    }
  }
}

.none-rows {
  width: 100%;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.43;
  color: $black;
  margin-bottom: 25px;

  // Two implementations of .none-rows specified 60px, and to
  // keep a single implementation, we use CSS "min()", but since
  // SASS complains about different units (%, px), we use "MIN",
  // since CSS works, and it's case-insensitive.
  // Ideally this should go away with recent versions of SASS
  margin-top: MIN(10%, 60px); /* stylelint-disable-line function-name-case */

  .icon {
    margin: auto;
    margin-bottom: 16px;
  }
}

.button-pill-primary {
  display: flex;
  width: 104px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $color-g01;
  cursor: pointer;
  font-size: 12px;
  font-weight: $gbm-medium;
  outline: 0;
  user-select: none;
  border-radius: 16px;
  transition: all 0.5s ease;

  &--active {
    border: none;
    background-color: $black;
    color: $white;
  }
}

// Alter global z-index of pentafone chat
// This is not the best approach but we have many elements with hight z-index in,
// for example, puppet component.
// TODO: re-calcultate global z-index.

.cx-side-button-group {
  z-index: 6 !important;
}

#ujet-launcher {
  width: 48px;
  height: 48px;

  iframe {
    width: 48px !important;
    height: 48px !important;
  }
}

.gbm-plus-logo {
  background-image: url(/images/logo.svg);
  height: 20px;
  width: 100px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.trading-logo {
  background-image: url(/images/trading-logo.svg);
  height: 67px;
  width: 40px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
