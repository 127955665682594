@import '../color-palette';
@import '../mixins';
@import '../typography';

$toast-padding: 24px;
$toast-close-button-size: 10px;

#toast-container {
  &.toast-bottom-full-width,
  &.toast-top-full-width {
    > div {
      width: 96%;
      margin: auto;
    }
  }

  &.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
    > div {
      margin: auto;
    }
  }

  > div {
    position: relative;

    &.toast {
      width: 672px;
      min-height: 64px;
      margin-top: 16px;
      padding: 0 $toast-padding;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $darkjungle;
      border-radius: 4px;
      box-shadow: none;
      user-select: none;
      pointer-events: auto;

      /* --------------------------------------------------------------------------

        NOTE:

        ngx-toastr adds a button with this class. This cannot be changed, and the content
        of the button cannot be changed, which is a "×" character.

        To override it, we set a fixed width and height, and set the font-size to 0.

        ---

        This element is also created before everything else in the toast, so we cannot rely
        on simple flex justification or alignment. So we keep the position:absolute, and
        set the right value to that of the toast x-padding.
      */
      .toast-close-button {
        position: absolute;
        width: $toast-close-button-size;
        height: $toast-close-button-size;
        top: 50%;
        right: $toast-padding;
        transform: translateY(-50%);
        font-size: 0;
        background-image: url('/images/icons/atm-alert-icn-close.svg');
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }

      .toast-title {
        display: none;
      }

      .toast-message {
        margin: 16px 0;
        margin-right: 16px + $toast-close-button-size;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: normal;
        line-height: 1.43;
        word-wrap: break-word;
        word-break: break-word;

        &::before {
          content: '';
          margin-right: 16px;
          min-width: 20px;
          width: 20px;
          height: 20px;
          display: inline-table !important;
        }

        p {
          margin: 0;
          padding: 0;

          * {
            display: inline;
          }
        }

        a {
          padding-bottom: 0.2rem;
          color: inherit;
          text-decoration: none;
          border-bottom: 1px dashed black;
          font-weight: $gbm-semibold;
        }
      }

      &.toast-info {
        background-color: $color-ghost-white;
        background-image: none !important;

        .toast-message {
          &::before {
            background-image: url('/style/assets/atm-icn-alert-info.svg');
          }
        }
      }

      &.toast-error {
        background-color: $color-amour;
        background-image: none !important;

        .toast-message {
          &::before {
            background-image: url('/style/assets/atm-icn-alert-wrong.svg');
            content: '';
            width: 20px;
            height: 20px;
          }
        }
      }

      &.toast-success {
        background-color: $color-solitude;
        background-image: none !important;

        .toast-message {
          &::before {
            background-image: url('/style/assets/atm-icn-alert-success.svg');
          }
        }
      }

      &.toast-warning {
        background-color: $color-old-lace;
        background-image: none !important;

        .toast-message {
          &::before {
            background-image: url('/style/assets/atm-icn-alert-warning.svg');
          }
        }
      }
    }
  }
}

#toast-container.toast-bottom-full-width > div,
#toast-container.toast-top-full-width > div {
  width: 96%;
  margin: auto;
}
