@import './_typography';
@import './mixins';
@import './color-palette';

.button {
  padding: 0;
  border: none;
  cursor: pointer;
  font-family: $font-worksans, sans-serif;
  font-size: 16px;
  font-weight: $gbm-semibold;
  outline: none;
  transition: (border 0.3s, all 0.3s) ease-in-out;
  user-select: none;

  @include border-radius(4px);

  &__loader,
  &-fab__loader {

    .dot {
      width: 8px;
      height: 8px;
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: $white;

      @include border-radius(4px);

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }

      @keyframes dot-keyframes {

        0% {
          opacity: 0.4;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0.4;
        }
      }
    }
  }

  &--big {
    width: 288px;
    height: 56px;
  }

  &--small {
    width: 192px;
    height: 56px;
  }

  &__loader {
    display: inline-flex;
    width: 36px;
    justify-content: space-between;
  }

  &--loading {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:disabled,
  &--disabled {
    border-color: transparent;
    background-color: $color-lightgrey;
    cursor: not-allowed;
    pointer-events: none;
  }

  &--dark {
    background-color: $black;
    color: $white;

    &:hover,
    &:focus,
    &.button--hover {
      border: 1px solid $black;
      background-color: transparent;
      color: $black-four;
    }

    &:disabled,
    &.button--disabled {
      border: none;
      background-color: $color-lightgrey;
      cursor: not-allowed;

      &:hover,
      &:focus {
        border: none;
        color: $white;
      }
    }

    &.button--loading {

      &:disabled,
      &.button--disabled {
        background-color: $black;
        cursor: not-allowed;
      }

      .dot {
        background-color: $white;
      }
    }
  }

  &--light {
    background-color: $white;
    color: $black-four;

    &:hover,
    &:focus,
    &.button--hover {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;
    }

    &:disabled,
    &.button--disabled {
      border: none;
      background-color: rgba(255, 255, 255, 0.5);
      color: rgba(18, 18, 18, 0.5);
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.5);
        color: rgba(18, 18, 18, 0.5);
      }
    }

    &.button--loading {

      &:disabled,
      &.button--disabled {
        background-color: $white;
        cursor: not-allowed;
      }

      .dot {
        background-color: $black;
      }
    }
  }

  &--transparent {
    background-color: $white;

    &:hover,
    &:focus,
    &.button--hover {
      border: 1px solid $white;
      background-color: transparent;
      color: $black;
    }
  }

  &--link {
    background-color: transparent;
    color: $black;
    font-size: 14px;
    font-weight: $gbm-medium;
    transition: none;

    &-hover,
    &:focus,
    &:hover {
      @include border-radius(0);

      border-bottom: 1px dashed $black;
    }
  }

  &--semibold {
    font-weight: $gbm-semibold;
  }

  &--bold {
    font-weight: $gbm-bold;
  }
}

.link {
  height: fit-content;
  padding: 0;
  color: $black;
  font-family: $font-worksans, sans-serif;
  font-size: 14px;
  font-weight: $gbm-medium;
  line-height: 1.45;
  text-decoration: none;
  background: none;
  border: none;
  border-bottom: 1px dashed $black;
  cursor: pointer;

  &--semibold {
    font-weight: $gbm-semibold;
  }

  &--bold {
    font-weight: $gbm-bold;

    &:hover,
    &:focus,
    &.link--hover {
      border-bottom: 1px dashed $black;
    }
  }

  &--light {
    color: $white;
    border-bottom: 1px dashed $white;
  }

  &--borderless {
    border-bottom: none;
  }

  &--borderless-bold {
    font-weight: $gbm-semibold;
  }
}

.button-fab {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: $black;
  color: $white;
  cursor: pointer;
  font-family: $font-worksans, sans-serif;
  font-size: 16px;
  font-weight: $gbm-semibold;
  outline: none;

  @include border-radius(20px);

  &--loading {

    .button-fab {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $black;
      background-image: initial;

      .dot {
        background-color: $white;
      }
    }
  }

  &__loader {
    display: inline-flex;
    width: 22px;
    justify-content: space-between;
  }

  &--expandable {
    position: relative;
    align-items: center;
    justify-content: center;
    transition: width 0.2s cubic-bezier(0.65, 0, 0.076, 1);

    span {
      opacity: 0;
      transition: visibility 0.2s ease-out, opacity 0.22s ease-out;
      visibility: hidden;
      white-space: nowrap;
    }

    &-right {

      &:focus,
      &:hover,
      &.button-fab--expanded {
        width: 192px;
        height: 40px;
        background-position: 14px;

        span {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-left {

      &:focus,
      &:hover,
      &.button-fab--expanded {
        width: 192px;
        height: 40px;
        background-position: 164px;

        span {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &--disabled {
    cursor: auto;
    background-color: $color-lightgrey;

    & + span {
      cursor: auto;
    }
  }

  &--small {
    width: 28px;
    height: 28px;
  }

  &--big {
    width: 50px;
    height: 50px;
  }

  &--add {
    background-image: url('/style/assets/atm-icn-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  &--back,
  &--next,
  &--filter,
  &--clear {

    &.button-fab--with-description {
      display: inline-flex;
      width: 75px;
      align-items: center;
      background: transparent;
      color: $black;
    }
  }

  &--deposit {

    .button-fab {
      background-image: url('/style/assets/atm-cta-tab-deposit.svg');
    }
  }

  &--transfer {

    .button-fab {
      background-image: url('/style/assets/atm-cta-tab-transfer.svg');
    }
  }

  &--withdraw {

    .button-fab {
      background-image: url('/style/assets/atm-cta-tab-withdraw.svg');
    }
  }

  &--filter {

    .button-fab {
      background-image: url('/style/assets/atm-icn-filter.svg');
    }
  }

  &--clear {

    .button-fab {
      background-image: url('/style/assets/icn-x.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &--deposit,
  &--transfer,
  &--withdraw {
    display: flex;
    width: 75px;
    height: 64px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &.button-fab--with-description {

      .button-fab {
        background-position: center;
        background-repeat: no-repeat;
      }

      span {
        margin-top: 8px;
        color: $black;
        font-family: $font-mabry;
        font-weight: $gbm-regular;
        font-size: 12px;
        line-height: 0.83;
      }

      &.button-fab--loading {

        .button-fab {
          background-color: $black;
          background-image: initial;
        }
      }
    }
  }

  &--filter,
  &--clear {
    flex-direction: row-reverse;

    &.button-fab--with-description {

      .button-fab {
        background-position: center;
        background-repeat: no-repeat;
      }

      span {
        margin-right: 16px;
      }
    }
  }

  &--back {
    background-image: url('/style/assets/icn-arrow-left.svg');
    background-position: center;
    background-repeat: no-repeat;

    &.button-fab--with-description {

      .button-fab {
        background-image: url('/style/assets/icn-arrow-left.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      span {
        margin-left: 16px;
      }
    }
  }

  &--next {
    background-image: url('/style/assets/icn-arrow-right.svg');
    background-position: center;
    background-repeat: no-repeat;

    &.button-fab--with-description {
      flex-direction: row-reverse;

      .button-fab {
        background-image: url('/style/assets/icn-arrow-right.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      span {
        margin-right: 16px;
      }
    }
  }
}
