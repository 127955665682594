@import './_typography';
@import './mixins';
@import './color-palette';

%input {
  height: 22px;
  padding: 8px;
  border: 1px solid $brown-grey;
  font-size: 14px;
  font-weight: $gbm-medium;
  outline: none;
  transition: (border 0.3s, box-shadow 0.3s, color 0.3s) ease-in-out;

  @include border-radius();

  @include placeholder {
    color: $brownish-grey;
    font-weight: $gbm-regular;
  }
}

.input {
  $class: input;

  position: relative;
  display: flex;
  width: 288px;
  flex-direction: column;
  font-size: 14px;

  &__label {
    display: block;
    height: 16px;
    margin-bottom: 8px;
    line-height: 1;
    font-size: 14px;

    &--icon {
      display: flex;
      justify-content: space-between;
    }
  }

  &--big {
    width: 360px;

    .input__label {
      visibility: hidden;
    }
  }

  &--fluid {
    width: 100%;
  }

  &__input {
    @extend %input;

    &:focus,
    &--focus {
      border-color: $black;
    }

    &--prefilled {
      background-color: rgba(0, 206, 122, 0.12);
    }

    &--big {
      border: none;
      border-bottom: 1px solid $brown-grey;
      font-size: 28px;

      @include border-radius(0);

      &:focus,
      &--focus {
        box-shadow: none;
      }
    }
  }

  &--currency {
    display: flex;

    &::before {
      position: absolute;
      top: 28px;
      left: 0;
      color: $black;
      content: '$';
      font-size: 28px;
      font-weight: $gbm-light;
    }

    &::after {
      position: absolute;
      top: 28px;
      right: 0;
      color: $black;
      content: 'MXN';
      font-size: 28px;
      font-weight: $gbm-light;
    }

    .input__input {
      padding-right: 80px;
      padding-left: 40px;
      text-align: center;
    }
  }

  &__select {
    @extend %input;

    position: relative;
    display: flex;
    height: 40px;
    background-color: $white;

    @include appearance(none);

    &:not(:focus):invalid {
      color: red;
    }

    + .icon--arrow {
      position: absolute;
      right: 16px;
      bottom: 12px;
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    height: 40px;

    &--status {
      @include position-right(8px);
      @include position-bottom(0);
    }

    &--right {
      @include position-right(16px);
      @include position-bottom(0);

      &::after {
        display: flex;
        align-items: center;
        color: $black;
        content: 'Mostrar';
        cursor: pointer;
        font-size: 10px;
        font-style: normal;
        font-weight: $gbm-bold;
        line-height: 1;
      }
    }
  }

  a {
    color: $black;
    text-decoration: none;
  }

  &__message {
    margin-top: 8px;
    color: $greyish-brown;
    font-size: 12px;

    &--link {
      width: fit-content;
      border-bottom: 1px dashed $black;
      font-weight: $gbm-medium;
    }
  }

  &__group {
    position: relative;
    display: flex;

    & .#{$class}__input {
      width: 100%;
    }

    .icon {
      position: absolute;
      cursor: pointer;

      &--delete {
        top: 1px;
        right: 1px;
        width: 64px;
        height: 38px;
        border-left: 1px solid $brown-grey;
        background-color: $white-smoke;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  &--read-only {
    cursor: not-allowed;

    & .#{$class}__input {
      border-color: $white-smoke;
      background-color: $white-smoke;
      color: $black;
      pointer-events: none;
    }
  }

  &--disabled {

    & .#{$class}__label {
      color: $brownish-grey;
    }

    & .#{$class}__select,
    & .#{$class}__input {
      background-color: $white-smoke;
      color: $steel-color;
      cursor: not-allowed;
    }
  }

  &--error {
    border-color: $color-alert-error;

    & .#{$class}__input {
      border-color: $color-alert-error;
    }

    & .#{$class}__message {
      color: $color-alert-error;
    }
  }

  &--success {
    border-color: $color-alert-success;

    & .#{$class}__input {
      border-color: $color-alert-success;
    }
  }
}
