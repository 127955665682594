$font-worksans: 'Work Sans';
$font-mabry: 'Mabry';

$gbm-light: 300;
$gbm-regular: 400;
$gbm-medium: 500;
$gbm-semibold: 600;
$gbm-bold: 700;
$gbm-extrabold: 800;
$gbm-black: 900;

.typography {

  &-1 {
    font-size: 58px;
    line-height: 68px;
  }

  &-2 {
    font-size: 48px;
    line-height: 58px;
  }

  &-3 {
    font-size: 42px;
    line-height: 53px;
  }

  &-4 {
    font-size: 32px;
    line-height: 42px;
  }

  &-5 {
    font-size: 24px;
    line-height: 32px;
  }

  &-6 {
    font-size: 20px;
    line-height: 30px;
  }

  &-b-1 {
    font-size: 16px;
    line-height: 26px;
  }

  &-b-2 {
    font-size: 14px;
    line-height: 22px;
  }

  &-caption {
    font-size: 12px;
    line-height: 20px;
  }

  &--regular {
    font-weight: $gbm-regular;
  }

  &--medium {
    font-weight: $gbm-medium;
  }

  &--semibold {
    font-weight: $gbm-semibold;
  }

  &--bold {
    font-weight: $gbm-bold;
  }

  &--secondary-typeface {
    font-family: $font-mabry;
  }
}
