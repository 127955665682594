@import './settings';
@import 'src/style/typography';
//Main

//Header

.widget-title {
  font-family: $font-worksans;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $widget-title;
  text-transform: uppercase;
}
//Body

//Footer
