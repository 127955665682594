@mixin border-radius($radius: 4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

/**
 * Transition
 */
@mixin transition($prop) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
  transition: $prop;
}

/**
 * Positioning element
 */
@mixin position-right($right: 8px) {
  right: $right;
}

@mixin position-bottom($bottom: 8px) {
  bottom: $bottom;
}

/**
 * Placeholder
 */
@mixin placeholder {

  &.placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }
}

/**
 * Appearance
 */
@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
  appearance: $appearance;
}

/**
 * Scrollbar styles
 * This is not global for now
 */
@mixin styled-scrollbar {
  scrollbar-color: $color-g07 transparent; /* stylelint-disable-line property-no-unknown */
  scrollbar-width: thin; /* stylelint-disable-line property-no-unknown */

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    // TODO: This style should be $color-g07 but all Trading is still using $gris-3
    background: $gris-3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $gris-2;
  }
}
