@import '../color-palette';
@import '../mixins';

.checkbox {
  input[type='checkbox'] {
    &.checkbox__input {
      display: none;

      & + label {
        position: relative;
        display: inline-flex;
        height: 16px;
        align-items: center;
        padding-left: 32px;
        cursor: pointer;
        font-size: 14px;
        line-height: 1;
        outline: none;
        user-select: none;

        @include transition(all 0.2s ease-in-out);

        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          box-sizing: border-box;
          content: '';

          @include border-radius(2px);
        }

        &::before {
          width: 16px;
          height: 16px;
          border: 1px solid $black;
        }

        &:focus {
          &::before {
            box-shadow: 0 0 0 4px rgba(0, 206, 122, 0.2);
          }
        }
      }

      &:checked + label {
        &::before {
          background: url('/style/assets/mol-checkbox-selected.svg') no-repeat
            center center $black;
        }
      }

      &:disabled + label {
        cursor: not-allowed;

        &::before {
          border-color: $brown-grey;
          background-color: $white;
        }
      }

      &:checked:disabled + label {
        cursor: not-allowed;

        &::before {
          background: url('/style/assets/mol-checkbox-selected-disabled.svg')
            no-repeat center center $brown-grey;
        }
      }
    }
  }
}

.checkbox-token {
  input[type='checkbox'] {
    &.checkbox__input {
      display: none;

      + label.checkbox__label {
        box-sizing: content-box;
        display: flex;
        width: 112px;
        height: 32px;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: $color-g01;
        cursor: pointer;
        font-size: 12px;
        font-weight: $gbm-medium;
        outline: 0;
        user-select: none;

        @include border-radius(16px);
        @include transition(all 0.5s ease);

        span {
          margin-left: 8px;
        }
      }

      &:checked + label.checkbox__label {
        border: none;
        background-color: $black;
        color: $white;

        &::after {
          left: 16px;
          background: $white;
        }
      }
    }
  }
}

.checkbox-toggle {
  input[type='checkbox'] {
    &.checkbox__input {
      display: none;

      + label.checkbox__label {
        box-sizing: content-box;
        position: relative;
        display: block;
        width: 28px;
        height: 12px;
        padding: 2px;
        border: none;
        background: $brown-grey;
        border-radius: 2em;
        cursor: pointer;
        outline: 0;
        user-select: none;

        @include transition(all 0.5s ease);

        &::after,
        &::before {
          position: relative;
          display: block;
          width: 12px;
          height: 12px;
          content: '';
        }

        &::before {
          display: none;
        }

        &::after {
          left: 0;
          background-color: $white;
          border-radius: 1em;
          transition: all 0.3s ease;

          @include transition(
            (all 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0s)
          );
        }
      }

      &:checked + label.checkbox__label {
        border: none;
        background-color: $black;

        &::after {
          left: 16px;
          background: $white;
        }
      }
    }
  }
}
