@import 'settings';
@import 'forms';
@import 'typography';

/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable function-name-case */

/* DEGRADADOS */

.bg-dark-90 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#141414+0,2e3130+100 */
  background: #141414;

  /* Old browsers */
  background: -moz-linear-gradient(top, #141414 0%, #2e3130 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #141414 0%, #2e3130 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #141414 0%, #2e3130 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#141414', endColorstr='#2E3130', GradientType=0);

  /* IE6-9 */
}

.bg-capri-47 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00D9CF+0,95EA4F+100 */
  background: #00d9cf;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #00d9cf 0%, #95ea4f 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #00d9cf 0%, #95ea4f 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #00d9cf 0%, #95ea4f 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00D9CF', endColorstr='#95EA4F', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.bg-white-90 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#FFFFFF+0,EFEFEF+100 */
  background: #ffffff;

  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #efefef 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #efefef 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#EFEFEF', GradientType=0);

  /* IE6-9 */
}

body {
  height: auto;
  /*  120px is an extra offset when the navigation is in fixed position  */
  min-height: calc(100vh + 120px) !important;
  font-family: $font-worksans, sans-serif !important;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $pale-grey;
  box-sizing: border-box;
  overflow-x: scroll;
  min-width: 1280px;

  * {
    box-sizing: border-box;
  }

  &.onverify {
    background-color: $white;

    &.onapp {
      background-color: $gris-5;
    }
  }

  &.no-scroll {
    overflow: hidden;
  }

  &.onapp {
    background-color: $gris-5;
    min-width: 1280px;

    .app-component {
      background-color: $pale-grey;
      height: 100%;
      min-width: 1280px;
    }
  }

  input {
    outline: none !important;
    font-family: $font-worksans;
    font-weight: 600;

    &:-moz-focus-inner {
      border: 0;
    }
  }

  html {
    overflow-x: hidden;
  }

  body {
    overflow-x: auto;
  }

  // Rules with !important because are top general rules andy only should be used for temporal states

  .general-hide {
    display: none !important;
  }

  .general-show {
    display: block !important;
  }

  .full-width {
    width: 100% !important;
  }

  .pointer-disabled {
    pointer-events: none;
    cursor: none !important;
  }

  /* --------------------------------------------------------------------------
    TODO (CHORE):

    This may be dead code. Check that nothing breaks and then delete it.
  // #toast-container {
  //   .toast {
  //     &.toast-error {
  //       padding: 15px;
  //       min-height: 70px;

  //       .toast-message {
  //         display: flex;
  //         align-items: center;
  //         flex-direction: row;
  //         min-width: 245px;
  //       }
  //     }
  //   }
  // }
  -------------------------------------------------------------------------- */
}

// LOADER

.loader {
  .ball-scale-multiple {
    .hb-loader {
      background-color: $kiwi-variant-1;
    }
  }
}

// HEADERS
// ..................................

h1 {
  color: $darkjungle;
  font-size: 60px;
  font-weight: $ft-medium;
  line-height: 72px;
  text-align: center;
  text-rendering: geometricPrecision;
  margin: 0;
}

h2 {
  color: $darkjungle;
  font-size: 50px;
  font-weight: $ft-medium;
  line-height: 47px;
  text-align: center;
  text-rendering: geometricPrecision;
  margin: 0;
}

h3 {
  color: $darkjungle;
  font-size: 40px;
  font-weight: $ft-medium;
  line-height: 47px;
  text-align: center;
  text-rendering: geometricPrecision;
  margin: 0;
}

h4 {
  color: $darkjungle;
  font-size: 36px;
  font-weight: $ft-medium;
  line-height: 33px;
  text-align: center;
  text-rendering: geometricPrecision;
  margin: 0;
}

#loading {
  width: 100%;
  margin-top: 40vh;

  .loading-container {
    margin: 0 auto;
    max-width: 250px;

    img {
      width: 100%;
    }

    .progress {
      margin-top: 20px;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.5);
      overflow: hidden;

      &::after {
        content: ' ';
        display: block;
        width: 100%;
        height: 4px;
        background: white;
        animation: load 15s linear infinite;
      }
    }
  }
}

// NAVEGACIÓN
// ..................................

.nav-secundario {
  font-size: 14px;
  font-weight: $ft-medium;
  color: $darkjungle;
  letter-spacing: 0.4px;
}

// PARAGRAPHS
// ..................................
@mixin paragraph-16 {
  color: $darkjungle;
  font-size: 16px;
  line-height: 24px;
}

.paragraph-16 {
  @include paragraph-16;
}

@mixin paragraph-14 {
  color: $darkjungle;
  font-size: 14px;
  line-height: 23px;
}

.paragraph-14 {
  @include paragraph-14;
}

@mixin paragraph-12 {
  color: $darkjungle;
  font-size: 12px;
  line-height: 18px;
}

.paragraph-12 {
  @include paragraph-12;
}

// LINKS

a {
  display: block;
  font-size: 14px;
  color: $darkjungle;

  &:link,
  &:visited {
    text-decoration: none;
  }

  &:active,
  &:hover {
    text-decoration: underline;
  }
}

/* Tabla */
// ..................................

/* GRID AJUSTADA */
// ..................................

/*
xs
Smartphones (portrait and landscape) -----------
20em == 320px

sm
iPads (portrait and landscape) -----------
48em == 768px
@media only screen and (min-width: 48em) {}

md
Desktops and laptops -----------
62em == 992px
@media only screen and (min-width: 62em) {}

lg
Large screens -----------
75em == 1200px
@media only screen and (min-width: 75em) {}
 */
@media only screen and (min-width: 48em) {
  .container {
    // width: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    // width: 1370px;
    margin-left: auto;
    margin-right: auto;
  }
}

.box {
  /* Mask: */
  background: $white;
  box-shadow: 0 4px 8px 0 $box-shadow;
  border-radius: 4px;
  padding: 16px 24px;
  margin-bottom: 16px;

  &.no-padding {
    padding: 0;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.bottom-0 {
    padding-bottom: 0;
  }
}

.col-xs,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-right: 10px;
  padding-left: 10px;
}

/* clases globales */

.display-none {
  display: none;
}

// iPads (portrait and landscape) -----------
// 48em == 768px
@media only screen and (min-width: 48em) {
  section.movil {
    display: none;
  }
}

//  lg
// Large screens -----------
// 75em == 1200px
@media only screen and (min-width: 75em) {
  section.movil {
    display: none;
  }
}

// Print configuration
@media print {
  @page {
    size: A1 landscape;
  }
}

@media print {
  .footer {
    position: relative;
    bottom: 0;
    line-height: 15px;
    padding-top: 50px;

    span {
      font-weight: 600;
    }
  }
}

@media print {
  body {
    &.onapp {
      overflow-x: initial;
      background-color: white;
    }

    * {
      visibility: hidden;
      display: block;

      hb-capital-templates {
        position: relative;
        visibility: visible;
      }

      hb-account-status,
      hb-docs-table,
      hb-customer-paper {
        display: none;
      }

      hb-navigation {
        height: 0;
        display: none;
      }

      .main-wrapper {
        padding-bottom: 0;
      }

      .main-right-content {
        display: none;
      }

      .wrapper-footer {
        display: none;
      }

      .constancyContainer {
        .header {
          display: none;
        }

        .document-menu {
          display: none;
        }
      }

      .documents {
        margin: 0 auto;

        .box {
          padding-top: 0;
          padding-bottom: 0;
          height: auto;
        }

        .no-print {
          display: none;
        }

        .mat-tab-header {
          display: none;
        }

        .documents-container {
          .documents-container-right-content {
            display: none;
          }

          .documents-container-left-content {
            width: 100%;
          }
        }

        .document-menu {
          display: none;
        }
      }

      .capitalGains {
        display: none;
      }

      .constancy {
        .header-section {
          display: none;
        }
      }

      .view-container {
        height: 100%;

        .cover {
          border: 0;

          &.detalle {
            font-size: 10px;
            padding: 0;

            .content-doc {
              &.detalle {
                .group {
                  width: 100%;
                  overflow: auto;
                  font-size: 10px;
                }
              }

              &.perdidas {
                overflow-x: hidden;
              }
            }
          }

          &.perdidas {
            padding: 0;

            .content-doc {
              &.perdidas {
                .group {
                  width: 100%;
                  overflow: auto;
                  font-size: 7px;
                }
              }
            }
          }
        }

        &.tax {
          width: 101%;
          position: relative;
        }

        .tab-selector {
          display: none;
        }

        #print {
          display: none;
        }

        #constancia-retencion {
          #constancia-content {
            ul {
              &.witholdings {
                font-size: 14px;
              }

              &.others {
                font-size: 14px;
              }
            }

            .tabla {
              overflow: auto;

              .tabla-container {
                width: auto;

                tr {
                  &.titulo {
                    height: 90px;

                    td {
                      font-size: 7px;
                    }
                  }

                  td {
                    font-size: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .documents {
    width: 100%;
  }

  #chmln-dom,
  script {
    display: none;
  }

  .print,
  .print * {
    visibility: visible;
  }

  .print {
    position: relative;
    top: 0;
    left: 0;

    &.capital {
      position: relative;
    }
  }
}
