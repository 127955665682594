// INPUT
// ..................................
$input-height: 42px;
$input-radius: 4px;
$input-padding: 0 0 0 15px;

// BUTTON
// ..................................
$button-radius: 100px;
$button-height: 40px;
$button-padding: 20px;

/* Uso mixin por que "@extend" no funciona con @media para responsive */
@mixin button-14() {
  color: $darkjungle;
  font-size: 14px;
  font-weight: $ft-bold;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  border-radius: $button-radius;
}

/* Uso @extend por que "@mixin" no se puede llamar hacia átras */

.button-14 {
  @include button-14();
}

@mixin button-12() {
  color: $darkjungle;
  font-size: 12px;
  font-weight: $ft-bold;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
  border-radius: $button-radius;
}

.button-12 {
  @include button-12();
}

@mixin paragraph-14() {
  color: $darkjungle;
  font-size: 14px;
  line-height: 23px;
}

.paragraph-14 {
  @include paragraph-14();
}

@mixin h6() {
  color: $darkjungle;
  font-size: 16px;
  font-weight: $ft-medium;
  line-height: 24px;
  text-rendering: geometricPrecision;
  //margin: 12px 12px 20px;
  margin-top: 0.1px;
  padding-top: 12px;
  padding-left: 16px;
}

h6 {
  @include h6();

  &.no-margin {
    margin: 0;
  }
}

@mixin h5() {
  color: $darkjungle;
  font-size: 28px;
  font-weight: $ft-medium;
  line-height: 33px;
  text-align: center;
  text-rendering: geometricPrecision;
  margin: 0;
}

h5 {
  @include h5();
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $gris-3;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $gris-3;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $gris-3;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $gris-3;
}

body {

  .mat-ink-bar {
    height: 2px;
  }
}
