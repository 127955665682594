@import '../color-palette';

.toggle-radio {
  &__group {
    display: inline-flex;

    label {
      display: flex;
      width: inherit;
      height: inherit;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-g04;
      border-right: none;
      background-color: $white;
      cursor: pointer;
      line-height: 1;
      font-size: 14px;
    }

    input[type='radio'] {
      display: none;

      &:checked {
        & + label {
          background-color: $color-g01;
          font-weight: $gbm-semibold;
        }
      }

      &:disabled {
        & + label {
          background-color: $white-smoke;
          color: $brownish-grey;
          cursor: not-allowed;
        }
      }
    }
  }

  &__input {
    display: flex;
    flex: 1;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      > * {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    &:last-child {
      > * {
        border-right: 1px solid $color-g04;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  &__label {
    margin-bottom: 8px;
    line-height: 1;
  }

  &--small {
    .toggle-radio {
      &__input {
        width: 54px;
      }
    }
  }
}
