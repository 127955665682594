/* --------------------------------- Roboto --------------------------------- */

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-regular.woff2')
      format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-regular.woff')
      format('woff');
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-500.woff2')
      format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-500.woff') format('woff');
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-700.woff2')
      format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-700.woff') format('woff');
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-900.woff2')
      format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('/style/assets/fonts/roboto/roboto-v27-latin-900.woff') format('woff');
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/lato-v17-latin-regular.woff2') format('woff2'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('/style/assets/fonts/lato-v17-latin-regular.woff') format('woff');
}

/* ---------------------------------- Mabry --------------------------------- */

/* Mabry-400 - regular */
@font-face {
  font-display: swap;
  font-family: 'Mabry';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('/style/assets/fonts/mabry/Mabry-Regular.eot');
  src: url('/style/assets/fonts/mabry/Mabry-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/style/assets/fonts/mabry/Mabry-Regular.woff') format('woff'),
    url('/style/assets/fonts/mabry/Mabry-Regular.ttf') format('truetype'),
    url('/style/assets/fonts/mabry/Mabry-Regular.svg#Mabry-Regular')
      format('svg');
}
/* Mabry-700 - MabryPro-Bold */
@font-face {
  font-display: swap;
  font-family: 'Mabry';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('/style/assets/fonts/mabry/MabryPro-Bold.eot');
  src: url('/style/assets/fonts/mabry/MabryPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/style/assets/fonts/mabry/MabryPro-Bold.woff') format('woff'),
    url('/style/assets/fonts/mabry/MabryPro-Bold.ttf') format('truetype'),
    url('/style/assets/fonts/mabry/MabryPro-Bold.svg#MabryPro-Bold')
      format('svg');
}

/* -------------------------------- Work Sans ------------------------------- */

/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/style/assets/fonts/work-sans/work-sans-v9-latin-300.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/work-sans/work-sans-v9-latin-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/style/assets/fonts/work-sans/work-sans-v9-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/work-sans/work-sans-v9-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('/style/assets/fonts/work-sans/work-sans-v9-latin-600.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/work-sans/work-sans-v9-latin-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/style/assets/fonts/work-sans/work-sans-v9-latin-700.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/style/assets/fonts/work-sans/work-sans-v9-latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
