@import 'settings';
@import 'icons';

.radio-container {
  margin-top: 10px;
  margin-bottom: 6px;

  .radiobox {
    margin-right: 25px;
    width: 141px;

    span {
      border: solid 2px rgba(0, 0, 0, 0.54);
      height: 20px;
      width: 20px;
    }

    .text {
      font-weight: normal;
      font-size: small;
      margin-top: 5px;
      margin-left: 10px;
    }

    &.disabled {

      span {
        border-color: $gris-12;
      }

      .text {
        color: $gris-12;
      }
    }
  }
}
